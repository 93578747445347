<template>
  <sdPageHeader title="Virtual stock"> <template v-slot:buttons>
      <div class="page-header-actions">
        <sdButton @click="createNewOpportunity" size="small" type="primary">
          <sdFeatherIcons type="plus" size="14" />
          Add Stock
        </sdButton>
        &nbsp;&nbsp;&nbsp;
        <sdButton @click="switchDeletedView" size="small">
          <sdFeatherIcons v-if="!deletedItems" type="trash" size="14" />
          {{ deletedItems ? "Current" : "Deleted" }} Products
        </sdButton>
      </div>
    </template></sdPageHeader>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Product filter">
          <a-form :model="productForm" layout="vertical">
            <a-row :gutter="25">
              <a-col :span="6">
                <a-form-item label="HS code" name="hsCodeId">
                  <a-select :size="'small'" v-model:value="productForm.hsCodeId" placeholder="HS Code" show-search
                    style="width: 100%" :options="hsCodeData" :not-found-content="hsCodeFetching ? undefined : null"
                    :filter-option="false" @search="searchHSCode">
                    <template v-if="hsCodeFetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Product type" name="productType">
                  <a-select size="small" v-model:value="productForm.productType" placeholder="product type">
                    <a-select-option :value="1"> OCTG</a-select-option>
                    <a-select-option :value="2"> OTHER</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6" :xs="6">
                <a-form-item label="Partner" name="partenaireId">
                  <a-select :size="'small'" v-model:value="productForm.partenaireId" placeholder="Please choose partner"
                    show-search style="width: 100%" :options="partnerData"
                    :not-found-content="partnerFetching ? undefined : null" :filter-option="false"
                    @search="searchPartner">
                    <template v-if="partnerFetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6" :xs="6">
                <a-form-item name="dealNumber" label="File Number">
                  <a-input-number class="custom" v-model:value="productForm.dealNumber" :style="{ width: '100%' }" />
                </a-form-item>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary" @click="onSubmit">
                  <span>Search</span>
                </a-button>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button @click="clearFilters" danger>
                  <span>Clear Filters</span>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </a-col></a-row>

    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Products">
          <template #button v-if="!deletedItems">
            <div class="card-actions">
              <a-button :type="selectedRowKeys.length == 1 ? 'primary' : ''" :disabled="selectedRowKeys.length != 1"
                @click="toggelInspectionRequestModal">
                <span>Inspection Request</span>
              </a-button>
              <a-button :type="selectedRowKeys.length == 1 ? 'primary' : ''" :disabled="selectedRowKeys.length != 1"
                @click="toggelTransportRequestModal">
                <span>Transport Request</span>
              </a-button>
              <a-tooltip :title="cantOrder
                ? 'The selected products are not part of the same quotation.'
                : ''
                ">
                <a-button :type="selectedRowKeys.length > 0 ? 'primary' : ''" :disabled="cantOrder"
                  @click="orderProducts()">
                  <span>Create PO to Supplier</span>
                </a-button>
              </a-tooltip>
              <a-button :type="selectedRowKeys.length > 0 ? 'primary' : ''" @click="createSale()">
                <span>Create Sale Offer</span>
              </a-button>
            </div>
          </template>
          <div>
            <TableWrapper class="table-data-view table-responsive">
              <a-table :row-key="record => record.id" :columns="columns" :dataSource="productsData"
                :pagination="pagination" @change="onHandleTableChange" :rowSelection="rowSelection" :loading="isLoading">
              </a-table>
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
    </a-row>
    <HistoryModal v-if="showHistoryModal" entity="Product" :entityId="targetProduct" @Cancel="toggelHistory">
    </HistoryModal>
    <InspectRequestModal v-if="showInspectionRequest" :product="targetProduct" @Cancel="toggelInspectionRequestModal" />
    <TransportRequestModal v-if="showTransportRequest" :product="targetProduct" @Cancel="toggelTransportRequestModal" />
  </Main>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  createVNode,
} from "vue";
import { useStore } from "vuex";
import { Main } from "../styled";
import moment from "moment";
import useHsCodeSearch from "../../composable/useHsCodeSearch";
import usePartnerSearch from "../../composable/usePartnerSearch";
import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
const HistoryModal = defineAsyncComponent(() => import("@/components/businessComponents/HistoryModal.vue"));
const InspectRequestModal = defineAsyncComponent(() => import("@/view/deal/inspection/InspectRequestModal.vue"));
const TransportRequestModal = defineAsyncComponent(() => import("@/view/deal/transport/TransportRequestModal.vue"));

const columns = [
  {
    title: "LOT",
    dataIndex: "deal",
    key: "deal",
  },
  {
    title: "Quotation number",
    dataIndex: "dealNumber",
    key: "dealNumber",
  },
  {
    title: "Partner",
    dataIndex: "partenaireName",
    key: "partenaireName",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Unit of Measurement",
    dataIndex: "unitOfMeasureName",
    key: "unitOfMeasureName",
  },
  {
    title: "Unit Price",
    dataIndex: "priceAndCurrency",
    key: "priceAndCurrency",
  },
  {
    title: "Location",
    dataIndex: "stockLocation",
    key: "stockLocation",
  },
  {
    title: "Condition",
    dataIndex: "condition",
    key: "condition",
  },
  {
    title: "Used in purchase",
    dataIndex: "existInPurchase",
    key: "existInPurchase",
  },
  {
    title: "Files",
    dataIndex: "files",
    key: "files",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const PictureProduct = 1;
const MTR = 3;
const InspectionReport = 4;
const SupplierProposal = 5;

const Products = defineComponent({
  name: "Products",
  components: {
    Main,
    HistoryModal,
    InspectRequestModal,
    TransportRequestModal
  },

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const router = useRouter();
    const ProductApi = computed(() => state.ProductApi.data.data);
    const ProductPagination = computed(() => state.ProductApi.data);
    const partnerApi = computed(() => state.partnerApi.data);
    const isLoading = computed(() => state.ProductApi.loading);
    const deletedItems = ref(false);

    pagination = {
      current: 1,
      pageSize: 10,
      showSizeChanger: true
    };
    onMounted(() => {
      dispatch("partnerInfo");
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      };

      dispatch("allVirtualGetDataWithFiles", {
        pagination,
      });
    });

    const clearFilters = () => {
      productForm.hsCodeId = "";
      productForm.dealNumber = "";
      productForm.productType = "";
      productForm.partenaireId = "";
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      };
      dispatch("allVirtualGetDataWithFiles", {
        pagination,
      });
    };
    const getProductCondition = (condition) => {
      switch (condition) {
        case 1:
          return "Brand New";
        case 2:
          return "UnusedI nspected";
        case 3:
          return "Unused not inspected";
        case 4:
          return "Unused Needs Refurbishmen";
        case 5:
          return "Used";
        case 7:
          return "Scrap";

        default:
          return "Not Defined";
      }
    };

    const getPhotoFileList = (files) => {
      return files.filter(
        (file) =>
          file.fileType == PictureProduct || file.fileType == "PictureProduct"
      );
    };

    const getMTRFileList = (files) => {
      return files.filter(
        (file) => file.fileType == MTR || file.fileType == "MTR"
      );
    };
    const getSupplierProposalList = (files) => {
      return files.filter(
        (file) =>
          file.fileType == SupplierProposal ||
          file.fileType == "SupplierProposal"
      );
    };

    const getReportFileList = (files) => {
      return files.filter(
        (file) =>
          file.fileType == InspectionReport ||
          file.fileType == "InspectionReport"
      );
    };
    const downloadFiles = async (filelist) => {
      await filelist.reduce(async (previousPromise, file) => {
        await previousPromise;

        return new Promise((resolve) => {
          const link = document.createElement("a");
          link.href = `${process.env.VUE_APP_API_ENDPOINT}api/File/download?Id=${file.id}`;
          link.download = "";
          link.classList.add(`download-link-${file.id}`);

          link.click();
          document.body.appendChild(link);

          setTimeout(() => {
            resolve();
            const downloadLink = document.querySelector(
              `.download-link-${file.id}`
            );
            if (downloadLink) {
              downloadLink.remove();
            }
          }, 1000);
        });
      }, Promise.resolve());
    };
    const generateProductFiles = (product) => {
      var PhotoFileList = getPhotoFileList(product.files);
      var MTRFileList = getMTRFileList(product.files);
      var ReportFileList = getReportFileList(product.files);
      var SupplierProposalList = getSupplierProposalList(product.files);

      if (product.files.length > 0) {
        return (
          <div class="table-actions">
            {MTRFileList.length > 0 ? (
              <a-button
                title="MTR"
                class="sliders"
                onClick={() => downloadFiles(MTRFileList)}
              >
                <sdFeatherIcons type="sliders" size={14} />
              </a-button>
            ) : null}
            {PhotoFileList.length > 0 ? (
              <a-button
                title="Photos"
                class="image"
                onClick={() => downloadFiles(PhotoFileList)}
              >
                <sdFeatherIcons type="image" size={14} />
              </a-button>
            ) : null}
            {ReportFileList.length > 0 ? (
              <a-button
                title="Inspection Report"
                onClick={() => downloadFiles(ReportFileList)}
              >
                <sdFeatherIcons type="book" size={14} />
              </a-button>
            ) : null}
            {SupplierProposalList.length > 0 ? (
              <a-button
                title="Supplier’s Official Proposal"
                onClick={() => downloadFiles(SupplierProposalList)}
              >
                <sdFeatherIcons type="file" size={14} />
              </a-button>
            ) : null}
          </div>
        );
      } else {
        return "No file attached";
      }
    };
    const generateProductActions = (productId) => {

      const historybutton = <div class="table-actions">
        <a-button
          title="History"
          class="sliders"
          onClick={() => toggelHistory(productId)}
        >
          <sdFeatherIcons type="clock" size={14} />
        </a-button>
      </div>;

      const restorebutton = <div class="table-actions">
        <a-button
          title="Recover"
          class="sliders"
          onClick={() => restorItem(productId)}
        >
          <sdFeatherIcons type="refresh-ccw" size={14} />
        </a-button>
      </div>;

      const deletebutton = <div class="table-actions">
        <a-button
          title="Delete"
          class="trash-2"
          onClick={() => handleDeleteProduct(productId)}
        >
          <sdFeatherIcons type="trash-2" size={14} />
        </a-button>
      </div>;

      if (!deletedItems.value) {
        return (
          <div>
            {deletebutton}
            &nbsp;
            {historybutton}
          </div>
        );
      } else {

        return (
          <div>
            {historybutton}&nbsp;{restorebutton}
          </div>
        );
      }
    };
    const generatePurchasesLinks = (purchaseUUIDs) => {
      return (
        <div>
          <div class="table-actions">
            {purchaseUUIDs.map((uuid) => (
              <div key={uuid}>
                <router-link class="edit" to={"/purchase/edit-purchase-order/" + uuid}>
                  <sdFeatherIcons
                    style={"color: " + getColorFromGuid(uuid)}
                    type="archive"
                    size={16}
                  />
                </router-link>
              </div>
            ))}
          </div>
        </div>
      );
    };


    const restorItem = (id) => {
      const confirm = window.confirm("Are you sure restore this?");
      if (confirm) {
        dispatch("productDealApiDataRestore", id);
      }
      return false;
    };
    const handleDeleteProduct = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("productStockApiDataDelete", { id });
      }
      return false;
    };
    function getColorFromGuid(guid) {
      const guidWithoutHyphens = guid.replace(/-/g, "");

      const red = parseInt(guidWithoutHyphens.substr(0, 2), 16);
      const green = parseInt(guidWithoutHyphens.substr(2, 2), 16);
      const blue = parseInt(guidWithoutHyphens.substr(4, 2), 16);

      return `rgb(${red}, ${green}, ${blue})`;
    }
    const productsData = computed(() => {
      if (ProductApi.value && ProductApi.value.length) {
        return ProductApi.value.map((product, key) => {
          let quantity = product.quantity ?? product.quantityInitial;
          return {
            key: key + 1,
            dealId: product.dealId,
            deal: (
              <div class="table-actions">
                {product.productCountInDeal}
                <router-link
                  class="edit"
                  to={"/purchase/edit-deal/" + product.dealId}
                >
                  <sdFeatherIcons
                    style={"color: " + getColorFromGuid(product.dealId)}
                    type="archive"
                    size={16}
                  />
                </router-link>
              </div>
            ),
            date: moment(product.deal.date).format("DD/MM/YYYY"),
            dealNumber: product.deal.number,
            id: product.id,
            productId: product.id,
            partnerId: product.partnerId,
            quantityVirtuelle: product.quantityVirtuelle,
            quantityInStock: product.quantityInStock,
            description: product.sizeId
              ? `${product.categoryName} ${product.sizeName}, ${product.weightName}, ${product.gradeName}, ${product.threadName}, ${product.rangeName}`
              : product.note,
            priceAndCurrency: `${product.priceForSales} ${product.currencyName}`,
            isPartialSale: product.isPartialSale,
            dealIsPartialSale: product.deal.partialSale,
            unitPrice: product.priceForSales,
            partenaireName: product.partenaireName,
            stockLocation: product.stockLocation,
            productCountInDeal: product.productCountInDeal,
            quantityInitial: product.quantityInitial,
            conditionName: getProductCondition(product.condition),
            files: generateProductFiles(product),
            action: generateProductActions(product.id),
            unitOfMeasureId: product.unitOfMeasureId,
            unitOfMeasureName: product.unitOfMeasureName,
            deliveryTime: product.deliveryTime,
            productType: product.productType,
            numberPackage: product.numberPackage,
            availability: product.availability,
            condition: product.condition,
            currency: product.currencyName,
            currencyId: product.currencyId,
            currencyName: product.currencyName,
            incotermId: product.incotermId,
            incotermName: product.incotermName,
            stockLocationMaps: product.stockLocationMaps,
            countryId: product.countryId,
            countryName: product.countryName,
            originCountryId: product.originCountryId,
            originCountryName: product.originCountryName,
            categoryId: product.categoryId,
            categoryName: product.categoryName,
            sizeId: product.sizeId,
            sizeName: product.sizeName,
            gradeId: product.gradeId,
            gradeName: product.gradeName,
            weightId: product.weightId,
            weightName: product.weightName,
            rangeId: product.rangeId,
            rangeName: product.rangeName,
            threadId: product.threadId,
            threadName: product.threadName,
            hsCodeId: product.hsCodeId,
            drift: product.drift,
            hsCodeName: product.hsCodeName,
            existInPurchase: product.existInPurchase ? generatePurchasesLinks(product.listPurchaseExistIn) : "No",
            note: product.note,
            quantity,
          };
        });
      } else {
        return [];
      }
    });

    var pagination = computed(() =>
      ProductPagination.value && ProductPagination.value.succeeded == true
        ? {
          current: ProductPagination.value.pageNumber,
          total:
            ProductPagination.value.recordsFiltered ??
            ProductPagination.value.recordsTotal,
          pageSize: ProductPagination.value.pageSize,
        }
        : {
          current: 1,
          pageSize: 10,
          showSizeChanger: true
        }
    );
    // #region modal function
    const handleOk = () => {
      emit("hideProducts", false);
      pagination = {
        current: 1,
        pageSize: 10000,
      };
    };
    const handleCancel = () => {
      emit("hideProducts", false);
    };
    const SelectedProduct = (product) => {
      let productToAdd = {
        productId: product.id,
        saleId: props.saleId,
        unitPrice: product.priceForSales,
        quantity: 0,
      };
      dispatch("productOrderSubmitData", { productToAdd });
      emit("SelectedProduct", productToAdd);
    };
    // #endregion

    // #region filters
    var productForm = reactive({
      layout: "vertical",
      hsCodeId: "",
      productType: "",
      partenaireId: "",
      dealNumber: "",
    });
    const productFormRef = ref([]);

    const onHandleTableChange = (paginationTable) => {
      pagination = {
        ...pagination,
        current: paginationTable.current,
        pageSize: paginationTable.pageSize,
      };
      if (deletedItems.value) {
        dispatch("allVirtualDeletedGetDataWithFiles", { pagination });
      } else {
        dispatch("allVirtualGetDataWithFiles", { pagination });
      }
    };

    const onSubmit = () => {
      productForm.isPartialSale = !!productForm.isPartialSaleInt;
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      };

      if (deletedItems.value) {
        dispatch("allVirtualDeletedGetDataWithFiles", { pagination });
      } else {
        dispatch("allVirtualGetDataWithFiles", { pagination });
      }
    };
    // #end region

    const { searchHSCode, hsCodeData } = useHsCodeSearch(productForm.hsCodeId);
    const { partnerData, searchPartner } = usePartnerSearch(
      productForm.partenaireId
    );
    // Table
    const selectedRowKeys = ref([]);
    const selectedProducts = ref([]);
    const rowSelection = {
      onChange: (keys, rows) => {
        selectedRowKeys.value = keys;
        addOrRemoveRow(rows);
      },
    };
    const addOrRemoveRow = (rows) => {
      if (selectedRowKeys.value.length > selectedProducts.value.length) {
        // Create a Set to keep track of unique product IDs
        const uniqueProductIds = new Set(selectedProducts.value.map(product => product.id));

        // Filter products that are not already in the uniqueProductIds Set
        const foundProducts = rows.filter(product => {
          if (!uniqueProductIds.has(product.id)) {
            uniqueProductIds.add(product.id);
            return true;
          }
          return false;
        });

        selectedProducts.value = selectedProducts.value.concat(foundProducts);
      } else {
        selectedProducts.value = selectedProducts.value.filter(obj =>
          selectedRowKeys.value.includes(obj.id)
        );
      }
      console.log("selectedProducts.value", selectedProducts.value);
    };

    const cantOrder = computed(() => {
      if (selectedProducts.value.length <= 1) {
        return false;
      }
      const firstDealId = selectedProducts.value[0].dealId;

      for (let i = 1; i < selectedProducts.value.length; i++) {
        if (selectedProducts.value[i].dealId !== firstDealId) {
          return true;
        }
      }

      return false;
    });

    // Table actions
    const orderProducts = () => {
      if (selectedProducts.value.length == 0) {
        return false;
      }

      const DealIsPartialSale =
        selectedProducts.value[0].dealIsPartialSale == 2;
      const numberOfProductsInDeal =
        selectedProducts.value[0].productCountInDeal;
      if (
        !DealIsPartialSale &&
        numberOfProductsInDeal != selectedProducts.value.length
      ) {
        Modal.confirm({
          title: "Partial Sale is not possible for this Quotation. ",
          content:
            "You need to add all products from this quotation to proceed with your order.",
          okText: "Add quotation products",
          icon: createVNode(ExclamationCircleOutlined),
          closable: true,
          onOk() {
            fillAndCreatePurchase();
          },
          cancelText: "I'am aware",

          onCancel() {
            createPurchase();
          },
        });
      } else {
        createPurchase();
      }
    };

    const createPurchase = () => {
      dispatch("fillPurchaseProducts", selectedProducts.value);
      router.push("/purchase/purchase-order");
    };
    const fillAndCreatePurchase = () => {
      dispatch(
        "addAllDealProductsToPurchase",
        selectedProducts.value[0].dealId
      );
      router.push("/purchase/purchase-order");
    };
    const createSale = () => {
      dispatch("fillSaleProducts", selectedProducts.value);
      router.push("/sale/sale-order");
    };

    const showInspectionRequest = ref(false);
    const toggelInspectionRequestModal = () => {
      targetProduct.value = selectedProducts.value[0];
      showInspectionRequest.value = !showInspectionRequest.value;
      dispatch("emptySingelProduct");
    };
    const showTransportRequest = ref(false);
    const toggelTransportRequestModal = () => {
      targetProduct.value = selectedProducts.value[0];
      showTransportRequest.value = !showTransportRequest.value;
      dispatch("emptySingelProduct");
    };

    const createNewOpportunity = () => {
      router.push("/purchase/deal");
    };

    ////
    const switchDeletedView = () => {
      deletedItems.value = !deletedItems.value;
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      };

      if (deletedItems.value) {
        dispatch("allVirtualDeletedGetDataWithFiles", { pagination });
      } else {
        dispatch("allVirtualGetDataWithFiles", { pagination });
      }
    };

    // history Logic
    const showHistoryModal = ref(false);
    const targetProduct = ref('')
    const toggelHistory = (productId) => {
      targetProduct.value = productId;
      showHistoryModal.value = !showHistoryModal.value;
    }

    return {
      showHistoryModal,
      targetProduct,
      toggelHistory,
      searchHSCode,
      hsCodeData,
      partnerData,
      searchPartner,
      pagination,
      ProductApi,
      columns,
      createSale,
      clearFilters,
      handleOk,
      handleCancel,
      SelectedProduct,
      productForm,
      productFormRef,
      onSubmit,
      onHandleTableChange,
      productsData,
      isLoading,
      partnerApi,
      selectedRowKeys,
      rowSelection,
      cantOrder,
      orderProducts,
      createNewOpportunity,
      switchDeletedView,
      deletedItems,
      addOrRemoveRow,
      showInspectionRequest,
      toggelInspectionRequestModal,
      showTransportRequest,
      toggelTransportRequestModal,
    };
  },
});
export default Products;
</script>
<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
  height: 38px;
}

.button-container {
  align-self: center !important;

  .ant-btn {
    height: 38px;
    width: 100%;
  }
}

.table-actions {
  gap: 10px;
  display: flex;
}

.card-actions {
  gap: 10px;
  display: flex;
}
</style>
